import { Extend as Extender } from '@flarum/core/forum';
import { extend } from 'flarum/extend';

import Page from 'flarum/components/Page';
import LogInModal from 'flarum/components/LogInModal';
import SignUpModal from 'flarum/components/SignUpModal';
import ForgotPasswordModal from 'flarum/components/ForgotPasswordModal';
import VerifyEmailModal from './components/VerifyEmailModal/VerifyEmailModal';

/**
 * Control access to routes depending if the user has confirmed
 * their email address.
 */
extend(Page.prototype, 'init', function () {
  /* Routes to ignore */
  let excludedRoutes = [
    'tags',       // The forum index
    'settings',   // The settings page
    'login',      // The login route
    'logout',     // The logout route
    'register'    // The register route
  ];

  /* Block routes and prompt user with modal if they haven't confirmed their email */
  if (app.session.user !== undefined) {
    if (!app.session.user.data.attributes.isEmailConfirmed && !excludedRoutes.includes(app.current.props.routeName)) {
      app.modal.show(new VerifyEmailModal());
    }
  } else {
    if (app.current.props.routeName == 'discussion') {
      app.modal.show(new LogInModal())
    }
  }
});

extend(LogInModal.prototype, 'onhide', function () {
  /* Block routes and prompt user with modal if they haven't confirmed their email */
  if (app.session.user === undefined) {
    window.location.href = app.route('tags');
    app.route.set('tags');
  }
});

extend(SignUpModal.prototype, 'onhide', function () {
  /* Block routes and prompt user with modal if they haven't confirmed their email */
  if (app.session.user === undefined) {
    window.location.href = app.route('tags');
    app.route.set('tags');
  }
});

extend(ForgotPasswordModal.prototype, 'onhide', function () {
  /* Block routes and prompt user with modal if they haven't confirmed their email */
  if (app.session.user === undefined) {
    window.location.href = app.route('tags');
    app.route.set('tags');
  }
});
