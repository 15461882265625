// Flarum helper functions
import { extend as extender, override } from 'flarum/extend';
import listItems from 'flarum/helpers/listItems';

// Flarum components used
import IndexPage from 'flarum/components/IndexPage';
import DiscussionPage from 'flarum/components/DiscussionPage';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import DiscussionHero from 'flarum/components/DiscussionHero';
import HeaderPrimary from 'flarum/components/HeaderPrimary';

// Advertisement components
import AdBanner from './components/AdBanner/AdBanner';
import AdColumn from './components/AdColumn/AdColumn';

// API object configuration
import axios from 'axios';
const api = (csrf) => axios.create({
  baseURL: '/api/',
  headers: {
    'X-CSRF-Token': csrf
  }
});

/**
 * Adds a button to the header which links back to the homepage.
 */
extender(HeaderPrimary.prototype, 'items', function (items) {
  items.add(
    'home',
    <a href="https://promote-ed.co.uk">Back to Home</a>
  );
});

/**
 * Adds spaces for advertisements on the homepage.
 */
override(IndexPage.prototype, 'view', function () {
  return (
    <div className="IndexPage">
      <div class="wrapper">
      <div id="adLeft" class="ad-column"></div>
        <div className="container page-content">
        <div id="adTop"></div>
          {this.hero()}
          <div className="sideNavContainer">
            <nav className="IndexPage-nav sideNav">
              <ul>{listItems(this.sidebarItems().toArray())}</ul>
            </nav>
            <div className="IndexPage-results sideNavOffset">
              <div className="IndexPage-toolbar">
                <ul className="IndexPage-toolbar-view">{listItems(this.viewItems().toArray())}</ul>
                <ul className="IndexPage-toolbar-action">{listItems(this.actionItems().toArray())}</ul>
              </div>
              {app.cache.discussionList.render()}
            </div>
          </div>
        </div>
        <div id="adRight" class="ad-column"></div>
      </div>
    </div>
  );
});

/**
 * Adds spaces for advertisements on the discussions page.
 */
override(DiscussionPage.prototype, 'view', function () {
  const discussion = this.discussion;

  return (
    <div className="DiscussionPage">
      <div class="wrapper">
        <div id="adLeft" class="ad-column"></div>
        <div className="container page-content">
            {app.cache.discussionList
            ? <div className="DiscussionPage-list" config={this.configPane.bind(this)}>
                {!$('.App-navigation').is(':visible') ? app.cache.discussionList.render() : ''}
              </div>
            : ''}

          <div className="DiscussionPage-discussion">
            {discussion
              ? [
                DiscussionHero.component({discussion}),
                <div className="container">
                  <nav className="DiscussionPage-nav">
                    <ul>{listItems(this.sidebarItems().toArray())}</ul>
                  </nav>
                  <div className="DiscussionPage-stream">
                    {this.stream.render()}
                  </div>
                </div>
              ]
              : LoadingIndicator.component({className: 'LoadingIndicator--block'})}
          </div>
        </div>
        <div id="adRight" class="ad-column"></div>
      </div>
    </div>
  );
});

/**
 * Asynchronously gets the advertisements and renders them on the
 * homepage.
 */
extender(IndexPage.prototype, 'init', function () {
    setTimeout(() => {
      let tagName = m.route().split("/").pop();   // Get the current tag from the URI

      // Pass the CSRF token into API requests
      this.ads = api(app.session.csrfToken)
        .get('ads', {
          params: {
            tag: tagName
          }
        })
        .then((response) => {
          if (response.data.hasOwnProperty('left')) {
            m.render(document.getElementById('adLeft'), <AdColumn align="left" ads={response.data} />);
          }

          if (response.data.hasOwnProperty('right')) {
            m.render(document.getElementById('adRight'), <AdColumn align="right" ads={response.data} />);
          }

          if (response.data.hasOwnProperty('top')) {
            m.render(document.getElementById('adTop'), <AdBanner ads={response.data} />);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 100);
});

/**
 * Asynchronously gets the advertisements and renders them on the
 * discussions page.
 */
extender(DiscussionPage.prototype, 'init', function () {
    setTimeout(() => {
      let tagName = this.discussion.tags()[0].slug();   // Get the current tag from the discussion object

      // Pass the CSRF token into API requests
      this.ads = api(app.session.csrfToken)
        .get('ads', {
          params: {
            tag: tagName
          }
        })
        .then((response) => {
          if (response.data.hasOwnProperty('left')) {
            m.render(document.getElementById('adLeft'), <AdColumn align="left" ads={response.data} />);
          }

          if (response.data.hasOwnProperty('right')) {
            m.render(document.getElementById('adRight'), <AdColumn align="right" ads={response.data} />);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 100);
});

export const extend = [];
