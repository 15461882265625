import Component from 'flarum/Component';

export default class extends Component {
  init() {
    let ads = this.props.ads;
    this.img = ads.top.img;
    this.url = ads.top.url;
  }

  view() {
    return (
      <div class="ad-banner">
        <a href={this.url} class="ad-banner__link--top" target="_blank">
          <img src={this.img} class="ad-banner__img--top" />
        </a>
      </div>
    );
  }
}
