import Modal from 'flarum/components/Modal';

export default class VerifyEmailModal extends Modal {
  init() {
    super.init();

    this.resend = (e) => {
      e.preventDefault();

      let id = app.session.user.data.id;

      fetch('/api/users/' + id + '/send-confirmation', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': app.session.csrfToken
        }
      })
      .then(response => document.getElementById('emailVerifyMessage').innerHTML = 'Email successfully sent.')
      .catch(error => console.log(error));
    }
  }

  title() {
    return 'Verify Email Address';
  }

  content() {
    return m('.Modal-body', this.body());
  }

  body() {
    return (
      <div class="Form">
        <div class="FormGroup">
          <p>
            You must verify your email address before you can access this forum. If you have
            not received this email, click the button below to resend the email.
          </p>
        </div>
        <br/>
        <div class="FormGroup">
          <button onclick={this.resend} class="Button Button--primary Button--block" type="submit" title="Resend Email Confirmation">
            <span class="Button-label">Resend Email Confirmation</span>
          </button>
        </div>
        <div class="FormGroup">
          <p style="margin-top:1em;text-align:center;" id="emailVerifyMessage"></p>
        </div>
      </div>
    )
  }

  config(isInitialized, context) {

  }

  onhide() {
    window.location.href = app.route('tags');
    app.route.set('tags');
  }
}
